<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getspcmSessionDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="spcmSessionObjList && spcmSessionObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcmSessionObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="spcmSessionEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showSpcmSessionDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSpcmSessionEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <SpcmSessionEdit :propOpenedInModal="true" :propSpcmSessionObj="spcmSessionEditObj" @emitCloseSpcmSessionEditModal="closeSpcmSessionEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmSessionEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSpcmSessionEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSpcmSessionDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmSessionDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcmSessionDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { SpcmSessions } from "../../../FackApi/api/spcmSession.js"
import SpcmSessionEdit from "./SpcmSessionEdit.vue"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "SpcmSessionList",
  components: {
    SpcmSessionEdit
  },
  data () {
    return {
      cvCardTitle: "SpcmSessions",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit SpcmSession",
      cvAddModalHeader: "Add SpcmSession",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "SpcmSession List Response",
      showModelSpcmSessionEdit: false,
      showModelSpcmSessionDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "spcm id", key: "spcm_id", class: "text-left align-text-top", sortable: true },
        { label: "session id", key: "session_id", class: "text-left align-text-top", sortable: true },
        { label: "session start ts", key: "session_start_ts", class: "text-left align-text-top", sortable: true },
        { label: "session end ts", key: "session_end_ts", class: "text-left align-text-top", sortable: true },
        { label: "session hosting platform", key: "session_hosting_platform", class: "text-left align-text-top", sortable: true },
        { label: "session meeting id", key: "session_meeting_id", class: "text-left align-text-top", sortable: true },
        { label: "session weblink", key: "session_weblink", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      spcmSessionObjList: null,
      spcmSessionEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    await this.getDateFilter()
    this.spcmSessionList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getspcmSessionDateWise () {
      this.spcmSessionList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * spcmSessionList
     */
    async spcmSessionList () {
      try {
        let spcmSessionListResp = await SpcmSessions.spcmSessionList(this, this.whereFilter)
        if (spcmSessionListResp.resp_status) {
          this.spcmSessionObjList = spcmSessionListResp.resp_data.data
          this.totalRows = spcmSessionListResp.resp_data.count
        }
        else {
          this.toastMsg = spcmSessionListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at spcmSessionList() and Exception:", err.message)
      }
    },
    /**
     * spcmSessionEdit
     */
    spcmSessionEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/spcmSession_edit/" + this.spcmSessionEditObj.session_id)
        }
        else {
          this.spcmSessionEditObj = item
          this.showModelSpcmSessionEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmSessionEdit() and Exception:", err.message)
      }
    },
    /**
     * showSpcmSessionDeleteDialog
     */
    showSpcmSessionDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSpcmSessionDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcmSessionDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcmSessionDelete
     */
    async spcmSessionDelete () {
      try {
        let spcmSessionDelResp = await SpcmSessions.spcmSessionDelete(this, this.delObj.session_id)
        await ApiResponse.responseMessageDisplay(this, spcmSessionDelResp)

        if (spcmSessionDelResp && !spcmSessionDelResp) {
          this.showModelSpcmSessionDelete = false
          return false
        }

        let index = this.spcmSessionObjList.indexOf(this.delObj)
        this.spcmSessionObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSpcmSessionDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcmSessionDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmSessionEditModal
     */
    closeSpcmSessionEditModal () {
      try {
        this.showModelSpcmSessionEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmSessionEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
